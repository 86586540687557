import React, { useContext, useState, useLazyRef, lazy, useMemo, useEffect } from 'react'
import {
    Switch,
    Route
} from "react-router-dom";
import { UserContext } from '../../context/provider/UserProvider';

import { LayoutMaster } from '../modules/layout/LayoutMaster';
import { PrivateRoute } from '../components/PrivateRoute';

import { Personas } from '../pages/registros/Personas';
import { Entidades } from '../pages/registros/Entidades';
import { Usuarios } from '../pages/registros/Usuarios';
import { CatastroTerritorial } from '../pages/registros/CatastroTerritorial';
import { CatastroComisaria } from '../pages/registros/CatastroComisaria';
import { Documentos } from '../pages/registros/Documentos';

import { Incidentes } from '../pages/monitoreo/Incidentes';
import { Estadisticas } from '../pages/monitoreo/Estadisticas';
import { Atencion } from '../pages/monitoreo/Atencion';
import { Permisos } from '../pages/configuraciones/Permisos';

import PageNotFound from '../pages/PageNotFound';
import { Principal } from '../pages/Principal';
import { TiposAlertas } from '../pages/registros/TiposAlertas';

import { CentrosPoblados } from '../pages/registros/CentrosPoblados';
import { Pobladores } from '../pages/registros/Pobladores';
import { CatastroCP } from '../pages/registros/CatastroCP';
import { IndicadoresCentrosPoblados } from '../pages/IndicadoresCentrosPoblados';
import { ConsultasReportes } from '../pages/Estadisticas/ConsultasReportes';
import { GestionBienesServicios } from '../pages/registros/GestionBienesServicios';
import { RegistroCentrosPoblados } from '../pages/registros/RegistroCentrosPoblados';


export function PrivateRoutes({ token }) {
    // const { stateUser } = useContext(UserContext);

    return (
        <>
            {
                token !== '' && localStorage.getItem('currentLocation') !== '/'
                && (
                    <LayoutMaster>
                        <Switch>
                            {/* PRINCIPAL */}
                            <PrivateRoute path='/principal' component={Principal} />
                            {/* <PrivateRoute path='/principal' component={IndicadoresCentrosPoblados} /> */}

                            {/* REGISTROS */}
                            <PrivateRoute path='/registros-personas' component={Personas} />
                            <PrivateRoute path='/registros-usuarios' component={Usuarios} />
                            <PrivateRoute path='/registros-entidades' component={Entidades} />
                            <PrivateRoute path='/division-politica' component={CatastroTerritorial} />
                            <PrivateRoute path='/registros-catastro-comisarias' component={CatastroComisaria} />
                            <PrivateRoute path='/registros-tipo-alertas' component={TiposAlertas} />
                            <PrivateRoute path='/registros-catastro-cp' component={CatastroCP} />
                            <PrivateRoute path='/registros-centros-poblados' component={CentrosPoblados} />
                            <PrivateRoute path='/registros-pobladores' component={Pobladores} />

                            <PrivateRoute path='/registros-centros-poblados-v2' component={RegistroCentrosPoblados} />

                            {/* MONITOREO */}
                            <PrivateRoute path='/monitoreo-incidentes' component={Incidentes} />
                            <PrivateRoute path='/monitoreo-estadisticas' component={Estadisticas} />
                            {/* <PrivateRoute path='/monitoreo-atencion' component={Atencion} /> */}

                            {/* ESTADISTICAS */}
                            <PrivateRoute path='/consultas-reportes' component={ConsultasReportes} />
                            
                            {/* CONFIGURACION */}
                            <PrivateRoute path='/configuraciones-permisos' component={Permisos} />                            



                            {/* OTROS */}
                            <PrivateRoute path='/registros-documentos' component={Documentos} />
                            <PrivateRoute path='/gestion-bienes-servicios' component={GestionBienesServicios} />


                            <PrivateRoute path='/registros-catastro-cp' component={CatastroCP} />


                            <Route path="*" component={PageNotFound} />

                        </Switch>
                    </LayoutMaster>
                )
        }
            
        </>
    )
}