import React, { useState } from 'react';

const BotonSwitch = ({
    isOpen = false,
    dioClick,
    disabled = false
}) => {
    return (
        <div
            className={`relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer transition-colors ${isOpen ? 'bg-blue-500' : 'bg-gray-300'} ${disabled ? 'pointer-events-none' : ''}`}
            onClick={() => dioClick(!isOpen)}
        >
            <span
                className={`inline-block w-4 h-4 transform bg-white rounded-full transition-transform ${isOpen ? 'translate-x-6' : 'translate-x-1'}`}
            />
        </div>
    );
};

export default BotonSwitch;
