import React, { useState, useContext, useEffect,useRef } from 'react';
import { UserContext } from '../../context/provider/UserProvider';
import logo from '../../assets/images/logoPrincipalCenPoblado.fw.png'
import packageJson from '../../../package.json'


export const Principal = () => {
    const { stateUser, signOut } = useContext(UserContext)
    const version = packageJson.version

    // const loadURL = () => {
    //   const url = 'https://www.bbva.pe/'; // Reemplaza con la URL que desees cargar
    //   iframeRef.current.src = url;
    // };
  

    return (
        <>
            <section className='w-full h-full flex items-center justify-center'>
                <div className='flex flex-col opacity-60'>
                    <img src={logo} />
                    <span className='w-full text-right text-xs'>v. {version}</span>
                </div>
            </section>

            {/* <div classsName='flex flex-col text-gray-300 text-5xl '>
                <span>
                    LOGEADO CORRECTAMENTEsssssssssss
                </span>
            </div>
            <div className='text-2xl flex flex-col '>
                <span> * NOMUSUARIO: {stateUser.nomUsuario}</span>
                <span> * CODUSUARIO: {stateUser.codUsuario}</span>
                <span> * TOKEN: {stateUser.token}</span>
                <span> * TOKEN EXPIRE: {stateUser.tokenExpire}</span>
                <span> * ROL: {stateUser.rol}</span>
                <span> * NOMCARPETA FOTO: {stateUser.nomCarpetaFoto}</span>
                <span> * NOM FOTO:{stateUser.nomFoto}</span>
                        * Menus: 
                        {   
                            stateUser.menus.map(m => {
                                return (
                                        <span> - {m.nomMenu}</span>
                                )
                            })
                        }
                        * PERMISOS: 
                        {   
                            stateUser.permisos.map(m => {
                                return (
                                        <span> - {m.nomPermiso}</span>
                                )
                            })
                        }
            </div> */}

        </>
    )
}






// {
//     "codUsuario": 1013,
//     "nomUsuario": "ylazaro",
//     "token": "eyJ4NXQiOiJNelJqTkRRM05tVTNZV0l5WVRFNU16YzFaV001TlRWbU4yRmtaRGd5T1RsaVpUSTNZekZtWkRsaFlXUXlPREl5T0dFNU0yTTFNV016WXpZMk5qWmlPUSIsImtpZCI6Ik16UmpORFEzTm1VM1lXSXlZVEU1TXpjMVpXTTVOVFZtTjJGa1pEZ3lPVGxpWlRJM1l6Rm1aRGxoWVdReU9ESXlPR0U1TTJNMU1XTXpZelkyTmpaaU9RX1JTMjU2IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJ5bGF6YXJvIiwiYXV0IjoiQVBQTElDQVRJT05fVVNFUiIsImlzcyI6Imh0dHBzOlwvXC9pZGVudGl0eXNlcnZlci1zZXJ2aWNlLmFiZXhhLnBlXC9vYXV0aDJcL3Rva2VuIiwiZ3JvdXBzIjpbIkFwcGxpY2F0aW9uXC94dHUtbW9iaWxlLXNwIiwib2ZmaWNlLWFnZW50IiwiQXBwbGljYXRpb25cL0NvbnNvbGUiLCJJbnRlcm5hbFwvc3lzdGVtX3ByaW1hcnlfb2ZmaWNlLWFnZW50IiwiSW50ZXJuYWxcL2V2ZXJ5b25lIiwiSW50ZXJuYWxcL3N5c3RlbV9wcmltYXJ5X3h0dS1tb2JpbGUtbWFuYWdlciIsInh0dS1tb2JpbGUtbWFuYWdlciJdLCJnaXZlbl9uYW1lIjoiWW9ya2xpbiIsInNxbFVzZXJDb2RlIjoiMTAxMyIsImF1ZCI6IlkyaDd1QXA1OWsxUXFpSkNSNFFHN3BKOEpUd2EiLCJuYmYiOjE3MDQ1NDk5OTYsImF6cCI6IlkyaDd1QXA1OWsxUXFpSkNSNFFHN3BKOEpUd2EiLCJzY29wZSI6ImJlZXgtbWFuYWdlci5yZWFkIGJlZXgtbWFuYWdlci53cml0ZSBvcGVuaWQgcHJvZmlsZSIsImV4cCI6MTcwNDU1MzU5NiwiaWF0IjoxNzA0NTQ5OTk2LCJmYW1pbHlfbmFtZSI6IkxhemFybyIsImp0aSI6IjJiOTlkZGExLWEwZjktNDE5Yy04ZTljLWYzZWUwMWFiNDkyNiIsImVtYWlsIjoieWxhemFyby5hYnhAZ21haWwuY29tIn0.Pigjzg9-gns4SkKbOu9_KxZKdAT2LrE_KcOZL8L9Mt87o2GIG00r-eFVfZLkE1HTJ45qcbuaBorfqRR0i4Ao_8p30SBN1JFnmFF1RG3bg96kJbMmCIgFT3MSCJcAxr8pC3zZWaIeXvvnRdaL-mTJwsxoZ5lMZeK5F37S32N0ADH55TI9m5HpTQFk0FUS3IuuzvXm8yAFU1rBnu8evMezETWY9P0U5D-jAOmxVi-MRhwWMIAlsi-p6BFknYR5l0BFYku8i6k6ViYDEQyZzb711gj5b9nG4TImJVIWGu5_4TNWInO52WzFQtz0tUNlJJILFvDoCCx6ybhFIGU5VhkSLQ",
//     "tiempoExpiraMiliSegundos": 0,
//     "tokenType": "",
//     "codUsuarioTipo": 0,
//     "codSesion": 0,
//     "rol": "1",
//     "nombre": "Yorklin",
//     "apellidoPaterno": "Lazaro",
//     "apellidoMaterno": "",
//     "menus": [
//         {
//             "codMenu": 1,
//             "nomMenu": "Registros",
//             "codMenuPadre": 1,
//             "urlMenu": "",
//             "orden": 1
//         }
//     ],
//     "permisos": [
//         {
//             "codPermiso": 1,
//             "nomPermiso": "Registros"
//         }
//     ],
//     "tokenExpire": "2024-01-06T14:06:36.555Z"
// }