import React, { useState } from 'react'
import { AuthFetch } from '../../services/api'
import { notify } from '../../utils/utils'

const urlApi = process.env.REACT_APP_PLAMIN_API + '/api'

export const useRegistroCentroPoblado = () => {

	const COD_ENTIDAD_PRUEBA = 4924

	const datosGeneralesDefault = {
		nomEntidad: '',
		dispositivoLegalCreacion: '',
		dispositivoLegalAdecuacion: '',
		mcp: '',
		ddn: '',
		telefonoFijoUno: '',
		numeroCelular: '',
		cantidadPobladorRegistroINEI: 0,
		cantidadPobladorRegistroManual: 0,
		correoElectronicoUno: '',
		nomResponsable: '',
		docAlcalde: '',
		direccion: '',
		urlImagenAlcalde: "",
		urlImagenCP: "",
		bloqueUnoCerrado: false
	}

	const caracteristicasDefault = {
		codEntidad: '',
		codEntidadCategoria: 0,
		codEntidadDispercion: 0,
		tieneLocalComunal: 0,
		codEntidadTransporte: 0,
		localColindanteNorte: '',
		localColindanteSur: '',
		localColindanteEste: '',
		localColindanteOeste: '',
		bloqueDosCerrado: false
	}

	const condicionesDefault = {
		codEntidad: '',
		localEscolarInicial: 0,
		localEscolarPrimaria: 0,
		localEscolarSecundaria: 0,
		institucionSuperior: 0,
		otro: 0,
		postaSalud: 0,
		puestoSalud: 0,
		centroSalud: 0,
		cantidadCalleViaUrbana: 0,
		cantidadCalleViaVecinal: 0,
		cantidadTrochaCarrozable: 0,
		cantidadRutaCaminoHerradura: 0,
		codEntidadAguaPotable: 0,
		codEntidadDesague: 0,
		codEntidadConectividad: 0,
		codEntidadElectricidad: 0,
		bloqueTresCerrado: false
	}	

	const equipamentoDefault = {
		codEntidad: 0,
		cantidadPlaza: 0,
		cantidadMercados: 0,
		cantidadEstadios: 0,
		cantidadJuzgadoPaz: 0,
		cantidadCementerio: 0,
		cantidadComisaria: 0,
		cantidadLocalComunal: 0,
		cantidadAtractivoTuristico: 0,
		cantidadColiseo: 0,
		cantidadParque: 0,
		bloqueCuatroCerrado: false
	}
	
	const [datosGenerales,setDatosGenerales] = useState(datosGeneralesDefault)
	const [caracteristicas,setCaracteristicas] = useState(caracteristicasDefault)
	const [condiciones,setCondiciones] = useState(condicionesDefault)
	const [equipamento,setEquipamento] = useState(equipamentoDefault)

	const [detalleCP,setDetalleCP] = useState({})

	const [dietasCP,setDietasCP] = useState([])
	const [transferenciasCP,setTransferenciasCP] = useState([])


	const funcionEditarDatosGenerales = (key,valor) => {
		setDatosGenerales(valorAnterior => {
			return {
				...valorAnterior,
				[key]: valor
			}
		})
	}
	const funcionEditarCaracteristicas = (key,valor) => {
		setCaracteristicas(valorAnterior => {
			return{
				...valorAnterior,
				[key]: valor
			}
		})
	}
	const funcionEditarCondiciones = (key,valor) => {
		setCondiciones(valorAnterior => {
			return{
				...valorAnterior,
				[key]: valor
			}
		})
	}
	const funcionEditarEquipamento = (key,valor) => {
		setEquipamento(valorAnterior => {
			return{
				...valorAnterior,
				[key]: valor
			}
		})
	}


	const registrarDatosGenerales = async ({
		urlImagenCP = '',
		urlImagenAlcalde = ''
	}) => {
		const response = await AuthFetch({
			url: urlApi + '/Entities/populatedCenter/general',
			method: 'POST',
			body: JSON.stringify(
				{
					...datosGenerales,
					urlImagenCP: urlImagenCP || datosGenerales.urlImagenCP,
					urlImagenAlcalde: urlImagenAlcalde || datosGenerales.urlImagenAlcalde,
				}
			)
		})

		return {
			CodResultado : response.isValid ? 1 : 0,
			DesResultado : response.isValid ? response.content : response.exceptions[0].description
		}
	}
	const editarDatosGenerales = async ({
		codEntidad,
		urlImagenAlcalde = '',
		urlImagenCP = ''
	}) => {
		const response = await AuthFetch({
			url: urlApi + '/Entities/populatedCenter/general',
			method: 'PUT',
			body: JSON.stringify({
				codEntidad: codEntidad,
				dispositivoLegalCreacion: datosGenerales.dispositivoLegalCreacion,
				dispositivoLegalAdecuacion: datosGenerales.dispositivoLegalAdecuacion,
				mcp: datosGenerales.mcp,
				ddn: datosGenerales.ddn,
				telefonoFijoUno: datosGenerales.telefonoFijoUno,
				numeroCelular: datosGenerales.numeroCelular,
				cantidadPoblacionINEI: datosGenerales.cantidadPobladorRegistroINEI,
				cantidadPoblacionManual: datosGenerales.cantidadPobladorRegistroManual,
				correoElectronico: datosGenerales.correoElectronicoUno,
				nomResponsable: datosGenerales.nomResponsable,
				dniAlcalde: datosGenerales.docAlcalde,
				direccion: datosGenerales.direccion,
				urlImagenCP: urlImagenCP || datosGenerales.urlImagenCP,
				urlImagenAlcalde: urlImagenAlcalde || datosGenerales.urlImagenAlcalde,
				bloqueUnoCerrado: datosGenerales.bloqueUnoCerrado
			})
		})

		return {
			CodResultado : response.isValid ? 1 : 0,
			DesResultado : response.isValid ? response.content : response.exceptions[0].description
		}
	}
	const registrarCaracteristicas = async (codEntidad) => {
		const response = await AuthFetch({
			url: urlApi + '/Entities/populatedCenter/characteristics',
			method: 'POST',
			body: JSON.stringify({...caracteristicas,codEntidad: codEntidad})
		})

		return {
			CodResultado : response.isValid ? 1 : 0,
			DesResultado : response.isValid ? response.content : response.exceptions[0].description
		}
	}
	const registrarCondiciones = async (codEntidad) => {
		const response = await AuthFetch({
			url: urlApi + '/Entities/populatedCenter/conditions',
			method: 'POST',
			body: JSON.stringify({...condiciones,codEntidad: codEntidad})
		})

		return {
			CodResultado : response.isValid ? 1 : 0,
			DesResultado : response.isValid ? response.content : response.exceptions[0].description
		}
	}
	const registrarEquipamento = async (codEntidad) => {
		const response = await AuthFetch({
			url: urlApi + '/Entities/populatedCenter/equipment',
			method: 'POST',
			body: JSON.stringify({...equipamento,codEntidad: codEntidad})
		})

		return {
			CodResultado : response.isValid ? 1 : 0,
			DesResultado : response.isValid ? response.content : response.exceptions[0].description
		}
	}

	const obtenerDetallesCP = async (codEntidad) => {
		const response = await AuthFetch({
			url: urlApi + '/Entities/populatedCenter/total?' + new URLSearchParams({
				codEntidad: codEntidad
			})
		})

		if(response.isValid){
			setDetalleCP(response.content[0] || {})
		}else{
			notify(response.exceptions[0].description,'error')
		}

	}

	const registrarJurisdiccion = async ({
		codEntidad = 0,
		poligonoJurisdiccion = ''
	}) => {
		const response = await AuthFetch({
			url: urlApi + '/Entities/populatedCenter/updatePolygon',
			method: 'PUT',
			body: JSON.stringify({
				codEntidad: codEntidad,
				poligonoJurisdiccion: poligonoJurisdiccion
			})
		})
		return {
			CodResultado: response.isValid ? 1 : 0,
			DesResultado: response.isValid ? response.content : response.exceptions[0].description
		}
	}

	const funcionesDietas = {
		listarDietasCP: async ({codEntidad,anio}) => {
			const response = await AuthFetch({
				url: urlApi + '/Entities/populatedCenter/diet?' + new URLSearchParams({
					codEntidad: codEntidad,
					Ano: anio
				})
			})
			if(response.isValid){
				setDietasCP(response.content)
			}else{
				notify(response.exceptions[0].description,'error')
			}
		},
		registrarDietaCP: async ({codEntidad,anio,mes,rango,fecha}) => {
			const response = await AuthFetch({
				url: urlApi + '/Entities/populatedCenter/diet',
				method: 'POST',
				body: JSON.stringify({
					codEntidad: codEntidad,
					ano: anio,
					mes: mes,
					rango: rango,
					fecha: fecha
				})
			})
			await funcionesDietas.listarDietasCP({
				codEntidad: codEntidad,
				anio: anio
			})
			return {
				CodResultado: response.isValid ? 1 : 0,
				DesResultado: response.isValid ? response.content : response.exceptions[0].description
			}
		},
	}

	const funcionesTransferencias = {
		listarTransferenciasCP: async ({codEntidad,anio}) => {
			const response = await AuthFetch({
				url: urlApi + '/Entities/populatedCenter/transfer?' + new URLSearchParams({
					codEntidad: codEntidad,
					ano: anio
				})
			})
			if(response.isValid){
				setTransferenciasCP(response.content)
			}else{
				notify(response.exceptions[0].description,'error')
			}
		},
		registrarTransferenciaCP: async ({codEntidad,anio,mes,rango,fecha}) => {
			const response = await AuthFetch({
				url: urlApi + '/Entities/populatedCenter/transfer',
				method: 'POST',
				body: JSON.stringify({
					codEntidad: codEntidad,
					ano: anio,
					mes: mes,
					rango: rango,
					fecha: fecha
				})
			})
			await funcionesTransferencias.listarTransferenciasCP({
				codEntidad: codEntidad,
				anio: anio
			})
			return {
				CodResultado: response.isValid ? 1 : 0,
				DesResultado: response.isValid ? response.content : response.exceptions[0].description
			}
		}
	}

	return {
		datosGeneralesDefault,
		caracteristicasDefault,
		condicionesDefault,
		equipamentoDefault,

		datosGenerales,
		caracteristicas,
		condiciones,
		equipamento,

		setDatosGenerales,
		setCaracteristicas,
		setCondiciones,
		setEquipamento,

		funcionEditarDatosGenerales,
		funcionEditarCaracteristicas,
		funcionEditarCondiciones,
		funcionEditarEquipamento,

		registrarDatosGenerales,
		editarDatosGenerales,
		registrarCaracteristicas,
		registrarCondiciones,
		registrarEquipamento,

		obtenerDetallesCP,
		detalleCP,
		registrarJurisdiccion,

		funcionesDietas,
		dietasCP,
		funcionesTransferencias,
		transferenciasCP
	}
}
